// URLS
export const getConfig = state => state?.configuration
export const getContactUsUrl = state => state?.configuration?.urls?.contact_us
export const getReturnPolicyUrl = state => state?.configuration?.urls?.return_policy
export const getTermsUrl = state => state?.configuration?.urls?.terms
export const getPrivacyPolicyUrl = state => state?.configuration?.urls?.privacy_policy
export const getMarketPlaceUrl = state => state?.configuration?.urls?.marketplace
export const getBcbsUrl = state => state?.configuration?.urls?.bcbsil
export const getDownloadElectronicConsentFormUrl = state => state?.configuration?.urls?.electronic_consent_form
export const getFSBUrl = state => state?.configuration?.urls?.family_supplemental_benefit
export const getOhcProviderUrl = state => state?.configuration?.urls?.ohc_provider
export const getOperatorHealthCenterUrl = state => state?.configuration?.urls?.operator_health_center

// CONTACT NUMBERS
export const getSiteContactNumber = state => state?.configuration?.contact_numbers?.site
export const getFundOfficeContactNumber = state => state?.configuration?.contact_numbers?.fund_office
export const getHealthPlanMarketplaceNumber = state => state?.configuration?.contact_numbers?.health_plan_marketplace
// eslint-disable-next-line max-len
export const getMemberServiceDepartmentNumber = state => state?.configuration?.contact_numbers?.member_services_department


// DOCUMENT UPLOAD CONFIG
// 1048576 = 1MB in Bytes
export const getDocumentUploadMaxFileSize = state => state?.configuration?.document_upload?.max_file_size * 1048576
export const getDocumentUploadAllowedExtensions = state => state?.configuration?.document_upload?.allowed_extensions
export const getDocumentUploadTotalFileSizeAllowed = state =>
  state?.configuration?.document_upload?.total_file_size_allowed * 1048576
export const getDocumentUploadTotalFileSizeAllowed_mb = state =>
  state?.configuration?.document_upload?.total_file_size_allowed

// FORCE SIGNING CONFIG
export const getRmspSettingsForceSigning = state => state?.configuration?.rmsp_settings?.force_signing
export const getEnrollmentSettingsForceSigning = state => state?.configuration?.enrollment_settings?.force_signing
export const getFundSettingsForceSigning = state => state?.configuration?.fund_settings?.force_signing
// eslint-disable-next-line max-len
export const getCommunicationPreferencesForceSigning = state => state?.configuration?.communication_preference_settings?.force_signing

export const getMonthlyHoursMax = state => state?.configuration?.monthly_hours?.max?.value
export const getMonthlyHoursMin = state => state?.configuration?.monthly_hours?.min?.value
